<template>
  <div class="step-container">
    <div class="text-center">
      <!--<div class="body4-bold primary">1/3단계</div>-->
      <div class="h6 main" style="margin-top:12px">내 플랫폼 서비스의 개설 정보<br class="v-mobile"> 완성하기</div>
    </div>
    <div class="flex-center">
      <div style="width:349px;margin-top:40px">
        <div>
          <div class="body3-medium sub2">내 서비스 이름</div>
          <input class="input font-primary margin-top-8" type="text"
                 placeholder="서비스명" :class="{'input-error': vErr.name}"
                 style="width:100%" maxlength="30"
                 v-model="order.service_name"/>
          <div class="body5 sub4" style="margin-top:4px">*서비스 개설 후 수정할 수 있습니다</div>
        </div>
        <div style="margin-top:28px">
          <div class="body3-medium sub2">내 서비스 주소</div>
          <div class="flex-align margin-top-8 input-sub3"
               :class="{'input-error': vErr.key}"
               style="border-radius:8px">
            <input class="input font-primary" type="text"
                   placeholder="영문으로 시작, 영문, 숫자만" id="service-key" autocomplete="new-password"
                   style="width:100%;border:0" maxlength="30"
                   @input="onKeyupServiceId" @blur="validKey"
                   v-model="order.key"/>
            <div style="padding:0 16px 0 0">.launchpack.co.kr</div>
          </div>
          <div class="err" v-if="err!==''">{{ err }}</div>

          <div class="body5 sub4" style="margin-top:4px">*서비스 주소 영문명은 변경되지 않습니다</div>
          <div class="body5 sub4">*서비스 개설 후 나만의 도메인 연결도 가능합니다</div>
        </div>
        <button class="button is-primary body2-medium" @click="clickNext" style="width:100%;height:52px;margin-top:40px">다음</button>
      </div>
    </div>
  </div>
</template>
<script>
  import UserAPIMixin from "../../../mixins/UserAPIMixin";

  export default {
    name: "ServiceCreateStep1",
    mixins:[
      UserAPIMixin
    ],
    props: {
      order: {
        type: Object
      }
    },
    created() {
    },
    data() {
      return {
        vErr: {
          name: false,
          key: false
        },
        err: ''
      }
    },
    methods: {
      validKey() {
        this.vErr.key = false;
        this.err = '';
        this.request.user.post('ui/super_builder/check_service', {key: this.order.key}).then(res=>{
          if(res.data.result) {
            this.vErr.key = true;
            this.err = '다른 주소를 입력해주세요.';
          }
        })
      },
      async clickNext() {
        this.vErr.name = false;
        this.vErr.key = false;
        if(this.order.service_name === '') {
          this.toast('서비스명을 먼저 입력해주세요');
          this.vErr.name = true;
          return;
        } else if(this.order.key === '') {
          this.toast('서비스 주소를 입력해주세요');
          this.vErr.key = true;
          return;
        }
        let res = await this.request.user.post('ui/super_builder/check_service', {key: this.order.key});
        if(res.data.result) {
          this.vErr.key = true;
          this.toast('이미 등록된 서비스 주소가 있습니다. 다른 주소로 입력해주세요.');
          return;
        }

        this.$emit('next');
      },
      onKeyupServiceId: function (e) {
        // if(/[0-9a-z]/.test(e.target.value[e.target.value.length-1])) {
        //   this.params.service_id = e.target.value;
        // }

        // eslint-disable-next-line no-useless-escape
        let regExp = /[\{\}\[\]\/?_.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
        // 특수문자 예외처리
        if (regExp.test(e.target.value)) {
          e.target.value = e.target.value.replace(regExp, '');
        } else if (e.target.value.length > 0 && /[a-z]/.test(e.target.value[0]) && /[0-9a-z]/.test(e.target.value[e.target.value.length-1])) {
          this.order.key = e.target.value;
        } else {
          this.order.key = '';
        }
        this.order.key = this.order.key.toLowerCase();
      },
    }
  }
</script>
<style lang="stylus" scoped>
  .input-sub3
    border 1px solid #e3e3e3
  .input-error
    border 1px solid #ff6600

  .err
    color red
    font-size 11px
    margin-top 4px
    margin-left 4px


  @media (min-width:1025px)
    .v-mobile
      display none
  @media (max-width:1024px)
    .step-container
      background-color #FAFAFA
      height 100%
      padding-top 40px
      padding-left 16px
      padding-right 16px


</style>
